import React, { useEffect, useDispatch, useState, useGlobal } from "reactn";
import logo from "../assets/images/logo.png";
import axios from "axios";
import "./InputStyle.css";
import { REACT_APP_API_URL } from "../App";
import InputOTP from "./InputOTP";
import swal from "sweetalert2";
import emailAnimation from "../assets/images/emailAnimation.gif";
import Firebaseui from "../components/firebaseui";

function Modal({ title, info, field }) {
  const [verify, setVerify] = useGlobal("verify");
  const [accountExist] = useGlobal("accountExist");
  const verifyAccountExist = useDispatch("verifyAccountExist");
  const validateOTP = useDispatch("validateOTP");
  const [displayFirebase, setDisplayFirebase] = useState('none')
  const [modalMode, setModalMode] = useState("default");

  const handleSubmit = async () => {
    let email = document.querySelector("#emailSignUp").value;
    setVerify({ email: email });
    verifyAccountExist();
    localStorage.setItem("email", email);
  };

  const hanbleDisplayFirebase = () => {
    if (displayFirebase === 'none') {
      setDisplayFirebase('block')
    } else {
      setDisplayFirebase('none')
    }

    return displayFirebase
  }

  const handleReSend = async (e) => {
    e.preventDefault();
    await axios
      .post(`${REACT_APP_API_URL}/core/v1/authentication/init`, {
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        swal.fire({
          title: "Se le ha envíado otro código, por favor revise.",
          text: "Recuerde que su código tiene una duración de 30 minutos.",
          imageUrl: emailAnimation,
          imageWidth: "300px",
        });
      })
      .catch((err) => console.error(err));
  };

  const handlePinComplete = () => {
    let code = "";
    document.querySelectorAll("#pincode-input-text").forEach((el, i) => {
      code += el.value;
    });
    setVerify({ email: verify.email, token: code });
    validateOTP();
  };

  const resetModal = () => {
    setModalMode("default");
  };
  useEffect(() => {
    if (accountExist === true) setModalMode("pinMode");
  }, [accountExist, title]);

  return (
    <>
    <div id="modal-sections" data-uk-modal="true" bg-close="false">
      <div className="uk-modal-dialog uk-padding">
        <a
          href="#"
          id="closeModal"
          className="uk-modal-close-default"
          onClick={() => resetModal()}
        >
          <i className="fas fa-times" />
        </a>

        <div className="uk-modal-body uk-text-center">
          <img
            className="uk-position-top-left logo"
            src={logo}
            width="303"
            alt="Opina y Gana"
          />
          <h2 style={{display: `${(displayFirebase === 'block') ? 'none' : 'block'}`}} className="uk-modal-title uk-text-center uk-modal-title--gray">
            {title}
          </h2>
          <p style={{display: `${(displayFirebase === 'block') ? 'none' : 'block'}`}} className="uk-padding-small uk-padding-remove-top">{info}</p>
    {/* <div style={{display: `${displayFirebase}`}}><Firebaseui /></div> */}
        </div>

        {modalMode === "default" ? (
          <input
            className={`${field.class} uk-margin`}
            type={field.type}
            id={field.id}
            name={field.name}
            placeholder={field.placeholder}
            style={{display: `${(displayFirebase === 'block') ? 'none' : 'block'}`}}
          />
        ) : (
          <div style={{display: `${(displayFirebase === 'block') ? 'none' : 'block'}`}}>
              <InputOTP id={"pincode-input-text"} />
          </div>
        )}

        <div style={{display: `${(displayFirebase === 'block') ? 'none' : 'block'}`}} className="uk-modal-footer uk-text-center">
          <button
            style={
              title == "Código de seguridad"
                ? { display: "none" }
                : { display: "block" }
            }
            className={`uk-padding-small uk-button uk-button-default uk-width-1 btn-secondary`}
            onClick={() => handleSubmit()}
          >
            Iniciar sesión
          </button>
          <button
            style={
              title == "Código de seguridad"
                ? { display: "block" }
                : { display: "none" }
            }
            className={`uk-padding-small uk-button uk-button-default uk-width-1 btn-secondary`}
            onClick={() => handlePinComplete()}
          >
            Iniciar sesión
          </button>
          <p
            style={
              title == "Código de seguridad"
                ? { display: "none" }
                : { display: "block" }
            }
          >
            ¿Aún no tienes una cuenta? <a href="/signup">Regístrate</a>
          </p>
          <p
            style={
              title == "Código de seguridad"
                ? { display: "none" }
                : { display: "block" }
            }
          >
            {/* ¿Quieres iniciar sesión con tu número? <a href="#" onClick={() => hanbleDisplayFirebase()}>Click aquí</a> */}
          </p>
          <p
            style={
              title == "Código de seguridad"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            ¿Tienes algún problema con tu código?{" "}
            <a href="/signup" onClick={handleReSend}>
              Reenviar
            </a>
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Modal;
