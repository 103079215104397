import React, { Component } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

let config = {
    apiKey: "AIzaSyDdTpYTlmuK7o1ddd99ecDZi8Yyxb9frcA",
    authDomain: "fir-react-410b4.firebaseapp.com",
    databaseURL: "https://fir-react-410b4.firebaseio.com",
    projectId: "fir-react-410b4",
    storageBucket: "fir-react-410b4.appspot.com",
    messagingSenderId: "1096919129567",
    appId: "1:1096919129567:web:fb0af161358e5dbd97786a"
}

firebase.initializeApp(config)

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInSuccessUrl: '/signedIn',
    signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PHONE_SIGN_IN_METHOD,
          recaptchaParameters: {
            type: 'image', 
            size: 'normal', 
            badge: 'bottomleft' 
          },
          defaultCountry: 'DO', 
          whitelistedCountries: ['DO', '+1']
        },
      ]
};




class Firebaseui extends Component {

    render() {
        return (
            <div>
                <p>Tu número telefónico nunca será mostrado a terceras partes.</p>
                <StyledFirebaseAuth uiConfig={uiConfig}  firebaseAuth={firebase.auth()} />
                {console.log([firebase.auth()])}
            </div>
        )
    }
}

export default Firebaseui
