import React from "react";

const Terms = () => {
    return ( <div
        id="modal-container"
        className="uk-modal-container"
        data-uk-modal="true"
      >
        <div className="uk-modal-dialog uk-modal-body">
          <a
            href="#"
            className="uk-modal-close-default"
            type="button"
          >
            <i className="fas fa-times" />
          </a>
          <div className="uk-container">
            <h2 className="uk-modal-title uk-text-center text-secondary--no-shadow">
              POLÍTICA PRIVACIDAD TATANCA
            </h2>

            <p>
              TATANCA puede recopilar información sobre los anuncios e información que ve mientras navega en nuestro sitio web. Parte de esta información se comparte con terceros para fines de análisis e informes. Los detalles de la información recopilada se proporcionan a continuación.
            </p>
            <p>
            TATANCA toma muy en serio su compromiso con la protección de datos personales y ha redactado su política de mejores prácticas de manejo de datos personales.
            </p>
            <p>
            Para las solicitudes de acceso al sujeto, si sospecha de cualquier uso indebido de parte nuestra o pérdida o acceso no autorizado a su información personal o para retirar su consentimiento para el procesamiento de sus datos personales, comuníquese al correo indicado debado.
Si tiene algún comentario o consulta sobre nuestra política de privacidad, o si desea presentar una queja, póngase en contacto con nosotros al correo arriba indicado.

            </p>
           
            <p>
            Email Oficial Cumplimiento :   <a href= "mailto: info@llenaygana.com" target="_blank" rel="noopener noreferrer">info@llenaygana.com</a>
            </p>
            <h3 className="uk-text-bold">
            RECOPILACIÓN DE DATOS
            </h3>
            <p>
            Únicamente se recopilarán los datos estrictamente necesarios para llevar a cabo la normal actividad del servicio. Ajustándose al principio de minimización de datos.
            </p>
            <p>
            Recopilamos información personal que nos envía activamente durante el uso de nuestros sitios web o servicios. Por ejemplo:
            </p>
            <p>
            Cuando se registra en uno de nuestros sitios web o para nuestros servicios, puede estar proporcionando cierta información personal, como su nombre, dirección de correo electrónico, nombre de pantalla, contraseña e información demográfica, como datos de nacimiento; y
            </p>
            <ul style={{color: '#263a4c'}}>
              <li>Puede estar proporcionando información en el transcurso del correo electrónico, las interacciones de soporte al cliente y las encuestas.</li>
            </ul>
            <p>Los datos personales recopilados que identifiquen a la persona serán en todo caso de mayores de 16 años. TATANCA se reserva el derecho a tomar las medidas oportunas para comprobar la veracidad de la edad.</p>
            <p>En todo caso estos datos serán de carácter personal identificativos y no sensibles, podrán ser:</p>
            <ul style={{color: '#263a4c'}}>
              <li>Correo electrónico</li>
              <li>Teléfono</li>
              <li>Nombre y apellidos</li>
              <li>Cédula de identidad y electoral o pasaporte</li>
              <li>Dirección</li>
              <li>Revisión de portafolio en buró de crédito</li>
            </ul>
            <p>
            Se estará recopilando identificadores personales, tales como:
            </p>
            <ol style={{color: '#263a4c'}}>
              <li>Dirección IP para la detección de fraudes y una amplia segmentación geográfica.</li>
              <li>ID de usuario único de TATANCA para aproximar el número de usuarios únicos en nuestra red.</li>
            </ol>
            <p>
            Además, TATANCA trabaja con proveedores de servicios de terceros limitados para la validación de anuncios de campaña, evaluación de alcance y segmentación amplia. TATANCA está en contacto con cada uno de nuestros proveedores de servicios para garantizar que sus procesos y datos cumplan con efectiva protección de datos personales.
            </p>
            <p>
            Los elementos de datos anteriores pueden ser compartidos con recopiladores de datos, que tienen su formal cumplimiento con reglas estrictas de protección de datos. 
            </p>
            <p>
            Ocasionalmente, los anunciantes nos piden que recopilemos datos personales proporcionados por el usuario (como el nombre, la dirección de correo electrónico) a través de un formulario en línea completado por el usuario. 
            </p>
            <h3 className="uk-text-bold">
            LA INFORMACIÓN RECOPILADA PUEDE SER ALMACENADA O PROCESADA EN: 
            </h3>
            <ul style={{color: '#263a4c'}}>
              <li>Amazon Web Services (AWS) - Servicios de servidores</li>
              <li>Facebook - Productos y servicios</li>
              <li>GOOGLE Cloud Platform  - Servicios de hosting y de nube</li>
              <li>Hotjar - Productos y servicios</li>
              <li>Linkedin - Productos y servicios</li>
              <li>LOTAME - Productos y servicios</li>
              <li>MOAT - Productos y servicios</li>
              <li>Sizmek - Productos y servicios</li>
            </ul>
            <p>
            Cada herramienta cuenta con sus respectivas políticas de privacidad y manejo de datos:
            </p>
            <ul>
            <li><a target={'_blank'} href = "https://aws.amazon.com/privacy/" rel="noopener noreferrer">Amazon Web Services (AWS)</a></li>
            <li><a target={'_blank'} href = "https://about.ads.microsoft.com/en-us/resources/policies/microsoft-advertising-privacy-policy" rel="noopener noreferrer">Bing</a></li>
            <li><a target={'_blank'} href = "https://support.google.com/displayvideo/answer/2896325?hl=en" rel="noopener noreferrer">Display and Video 360º</a></li>
            <li><a target={'_blank'} href = "https://www.facebook.com/policy.php" rel="noopener noreferrer">Facebook</a></li>
            <li><a target={'_blank'} href = "https://policies.google.com/technologies/partner-sites" rel="noopener noreferrer">Google AdSense</a></li>
            <li><a target={'_blank'} href = "https://marketingplatform.google.com/about/analytics/terms/us/" rel="noopener noreferrer">Google Analytics</a></li>
            <li><a target={'_blank'} href = "https://cloud.google.com/security/privacy/" rel="noopener noreferrer">Google Cloud Platform (GCP)</a></li>
            <li><a target={'_blank'} href = "https://www.google.com/analytics/tag-manager/use-policy/" rel="noopener noreferrer">Google Tag Manager</a></li>
            <li><a target={'_blank'} href = "https://policies.google.com/privacy?hl=en-US" rel="noopener noreferrer">Google</a></li>
            <li><a target={'_blank'} href = "https://www.hotjar.com/legal/policies/privacy" rel="noopener noreferrer">HotJar</a></li>
            <li><a target={'_blank'} href = "https://www.linkedin.com/legal/privacy-policy" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a target={'_blank'} href = "https://www.lotame.com/about-lotame/privacy/" rel="noopener noreferrer">Lotame</a></li>
            <li><a target={'_blank'} href = "https://www.oracle.com/legal/privacy/services-privacy-policy.html" rel="noopener noreferrer">Moat</a></li>
            </ul>
            <h3 className="uk-text-bold">
            PUBLICIDAD BASADA EN INTERESES 
            </h3>
            <p>
            Utilizamos un servicio de plataforma de gestión de datos (DMP) proporcionado por Lotame para la publicidad basada en intereses. Los datos de terceros en Lotame no contiene información de identificación personal (PII); sin embargo, TATANCA podría almacenar a través de servicios de hosting de terceros información PII. El DMP de Lotame crea perfiles a través del uso de cookies, etiquetas de píxel, SDK (kits de desarrollo de software), transferencias de archivos seguras, conexiones de servidor a servidor u otros identificadores únicos proporcionados a Lotame por los clientes, para aprender sobre su viaje. Esta información solo se utiliza para ayudarnos a mostrarle contenido de marketing más relevante. 
            </p>
            <p>Para obtener más información sobre esta cookie, visite la política de privacidad de Lotame : <a href="https://www.lotame.com/about-lotame/privacy/" target={'_blank'}>https://www.lotame.com/about-lotame/privacy/</a></p>
            <h3 className="uk-text-bold">
            MENORES DE EDAD 
            </h3>    
            <p>
            Nuestra publicidad nunca se dirige intencionalmente hacia niños menores de 13 años.
            </p>       
            <h3 className="uk-text-bold">
            POLITICA DE COOKIES 
            </h3>        
            <p>
            A medida que utiliza nuestros sitios web, su información personal también puede ser recopilada pasivamente por nosotros y nuestros socios a través del uso de:</p>   
            <p>
              <b>Cookies: </b>pequeños archivos de texto que contienen una cadena de caracteres que se utiliza para identificar un navegador de Internet. En dichos archivos hay información sobre sesión que se tenga en el sitio, la navegación que haga el usuario.  Estos archivos pueden ser retenidos en cada dispositivo utilizado y con capaces de proveer información sobre preferencias de uso, ajustes, autenticación u otro tipo de información para futuras visitas.
            </p>
            <p>
              <b>Píxeles/balizas: </b>Son JavaScript, iFrames o imágenes transparentes que se utilizan para comprender cómo interactúa con un sitio Web.
            </p>
            <p>
              <b>Identificaciones estadísticas: </b>un método probabilístico de identificar un dispositivo basado en un conjunto de sus características que tienen una probabilidad razonable de ser único en el agregado (por ejemplo, usando un hash de la dirección IP, cadena de usuario-agente y resolución de la pantalla).
            </p>
            <p>Para que nosotros y nuestros socios proporcionemos información personalizada, servicios, herramientas y anuncios, la información que recopilamos puede incluir dirección IP, ubicación geográfica no precisa, detalles del navegador, sistema operativo, términos de búsqueda, tiempo invertido en sitios web, anuncios vistos y señales de intención (información inferida basada en intereses probables asociados con un sitio web/URL).</p>
            <p>Las señales de intención no incluyen información sensible (origen racial o étnico, opiniones políticas, creencias religiosas o similares, afiliación sindical, condiciones de salud física o mental o vida sexual) aunque pueden incluir información como productos para el cuidado de la piel y suplementos dietéticos.</p>
            <p>La tecnología TATANCA utiliza cookies de navegador para ayudar a controlar el número de veces que los usuarios ven anuncios individuales y para enviar mostrar mensajes relevantes.</p>
            <p>Los datos utilizados para realizar esta tarea incluyen:</p>
            <ol style={{color: '#263a4c'}}>
              <li>UserIDs anónimos y generados aleatoriamente.</li>
              <li>Una colección de campos que contienen anuncios/vista creativa cuenta.</li>
            </ol>
            <p>
            TATANCA almacena información en una cookie para ayudar a controlar el número de veces que se muestran anuncios individuales. Nuestras cookies de navegador expiran 30 minutos o 1 año después de su última actualización. Estas cookies se actualizan cada vez que el navegador del usuario realiza una solicitud a TATANCA para un anuncio.
            </p>
            <h3 className="uk-text-bold">
            COOKIES DE TERCEROS:
            </h3>
            <p>
            Nuestros socios, indicados más abajo, que también pueden colocar cookies en su navegador, tienen sus propias reglas y políticas con respecto a las cookies. Para obtener información sobre sus políticas de cookies, visite sus respectivos sitios web: 
            </p>
            <h3 className="uk-text-bold">
            LinkedIn
            </h3>
            <ul>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/BizoID" rel="noopener noreferrer">BizoID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/UserMatchHistory" rel="noopener noreferrer">UserMatchHistory</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/bcookie" rel="noopener noreferrer">bcookie</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/lang" rel="noopener noreferrer">Lang</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/lidc" rel="noopener noreferrer">lidc</a></li>
            </ul>
            <h3 className="uk-text-bold">
            Lotame:
            </h3>
            <ul>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_cc_dc" rel="noopener noreferrer">_cc_dc</a></li>
            <li>_cc_domain</li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_cc_id" rel="noopener noreferrer">_cc_id</a></li>
            </ul>
            <h3 className="uk-text-bold">
            Google Analytics
            </h3>
            <ul>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_ga" rel="noopener noreferrer">_ga</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_gid" rel="noopener noreferrer">_gid</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_gat" rel="noopener noreferrer">_gat</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/__utmz" rel="noopener noreferrer">__utmz</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/__utma" rel="noopener noreferrer">__utma</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/__utmb" rel="noopener noreferrer">__utmb</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/__utmc" rel="noopener noreferrer">__utmc</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/__utmt" rel="noopener noreferrer">__utmt</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/1P_JAR" rel="noopener noreferrer">1P_JAR</a></li>
            </ul>
            <h3 className="uk-text-bold">
            Google
            </h3>
            <ul>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/sid" rel="noopener noreferrer">SID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/sidcc" rel="noopener noreferrer">SIDCC</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/ssid" rel="noopener noreferrer">SSID</a></li>
            </ul>
            <h3 className="uk-text-bold">
            Display and Video 360º
           </h3>
            <ul>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/dsid" rel="noopener noreferrer">DSID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/ide" rel="noopener noreferrer">IDE</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/APISID" rel="noopener noreferrer">APISID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/HSID" rel="noopener noreferrer">HSID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/NID" rel="noopener noreferrer">NID</a></li>
            <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/SAPISID" rel="noopener noreferrer">SAPISID</a></li>
            </ul>
            <h3 className="uk-text-bold">
            BING
           </h3>
           <p>
           Acerca de cookies: 
           </p>
           <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/muid" rel="noopener noreferrer">MUID</a></li>
          <li><a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/muidb" rel="noopener noreferrer">MUIDB</a></li>
          <p>Facebook</p>
          <a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_fbp" rel="noopener noreferrer">https://cookiepedia.co.uk/cookies/_fbp</a>
          <p>Google AdSense</p>
          <a target={'_blank'} href = "https://cookiepedia.co.uk/cookies/_fbp" rel="noopener noreferrer">https://cookiepedia.co.uk/cookies/_fbp</a>
          <h3 className="uk-text-bold">
          RENUNCIA / ACEPTACIÓN FORMAL 
          </h3>
          <p className="uk-text-bold">Límites de exposición al anuncio</p>
          <p>Si desea que TATANCA no almacene información en una cookie (para ayudar a controlar el número de veces que se muestran los anuncios individuales), no deberá aceptar del botón de Términos y condiciones mostradas en la sección de registro como un campo obligatorio para ser parte del panel. De esta forma el sitio al que visita no tendrá la posibilidad técnica de recordar el manejo del usuario determinado en dicho sitio.</p>
          <h3 className="uk-text-bold">
          DATOS DE NEGOCIOS 
          </h3>
          <p>En el curso de la prestación de servicios TATANCA necesita recopilar y utilizar información sobre sus clientes, proveedores y prospectos de comunicación para garantizar que se cumplan las obligaciones contractuales. Además, los datos de los prospectos se utilizan para campañas de marketing, generación de prospectos y CRM. La información que recopilamos incluye nombres, títulos de trabajo, números de teléfono, direcciones de correo electrónico, direcciones comerciales y actividad en TATANCA.com.</p>
          <h3 className="uk-text-bold">
          PROCESAMIENTO/ANÁLISIS DE DATOS
          </h3>
          <p>TATANCA transfiere los datos a terceros para su uso. Transfiere datos también para su manejo, resguardo y clasificación.  La transferencia de los datos se hace bajo las más estrictas normas de seguridad, incluyendo el respeto de una Política de buenas prácticas recolección de datos a territorios donde sus suplidores ofrezcan sus servicios de hosting y procesamiento de datos.   </p>
          <p>Con el fin de proporcionar experiencias publicitarias de alta calidad y atractivas, capturamos y analizamos información específica sobre el entorno del navegador de un usuario y la ubicación geográfica a través de servicios de terceros proporcionados según hemos señalado anteriormente. Estas empresas utilizan sus propias cookies de navegador para capturar información como el navegador, el sistema operativo y la URL de referencia, así como para determinar si un anuncio se ha mostrado correctamente.</p>
          <h3 className="uk-text-bold">
          ¿CÓMO TATANCA SALVAGUARDA LOS DATOS PERSONALES? 
          </h3>
          <p>TATANCA se compromete a proteger la información personal del uso indebido, pérdida o acceso no autorizado. Disponemos de una serie de medidas técnicas y organizativas apropiadas para salvaguardar los datos personales: todos los datos se almacenan en servidores seguros y se controlan mediante derechos de acceso estrictos. </p>
          <p className="uk-text-bold">Política de pérdida de datos</p>
          <p>En caso de violación o pérdida de datos personales, TATANCA se compromete a: </p>
          <ol style={{color: '#263a4c'}}>
            <li>Tomar rápidamente todas las medidas correctivas necesarias y apropiadas para remediar las causas subyacentes de la infracción y realizar esfuerzos comerciales razonables para garantizar que dicha infracción no se repita. </li>
            <li>Notificar a los clientes y/o socios afectados sin demora, y en cualquier caso dentro de 72 (72) horas, proporcionando un detalle razonable de la violación probable impacto en los sujetos de datos. </li>
            <li>Tomar cualquier acción requerida por la ley aplicable y/o a petición razonable de los clientes afectados y/o socios.</li>
          </ol>
          <p>Como la tecnología publicitaria de TATANCA sólo almacena identificadores personales indirectos y no identifican a individuos específicos, así, en caso de incumplimiento, no sería necesario o posible ninguna notificación de individuos.  En el caso que se tenga información identificable de personas, se hará la respectiva notificación según nuestra Política de buenas prácticas recolección de datos </p>
          <h3 className="uk-text-bold">
          SOLICITUDES DE ACCESO DEL SUJETO / DETALLES DE CONTACTO
          </h3>
          <p>Las personas tienen derecho a solicitar la modificación, actualización o supresión de los datos personales que posea TATANCA. TATANCA se compromete a responder de manera oportuna y útil a las consultas y preocupaciones de protección de datos válidas de las partes afectadas.</p>
          <p>A tal solicitud, podemos requerir la verificación de identidad y buscar más información sobre la solicitud realizada. Cuando se nos permita legalmente hacerlo, podremos rechazar la solicitud y dar nuestras razones para ello. </p>
          <p className="uk-text-bold">Oficial Cumplimiento de Datos Personales </p>
          <p className="uk-text-bold">Email Oficial Cumplimiento:  <a href = "mailto: info@llenaygana.com" target="_blank" rel="noopener noreferrer">info@llenaygana.com</a> </p>
          <p>Para las solicitudes de acceso al sujeto, si sospecha de cualquier uso indebido o pérdida o acceso no autorizado a su información personal o para retirar su consentimiento para el procesamiento de sus datos personales, comuníquese al correo indicado arriba.</p>
          <p>Si tiene algún comentario o consulta sobre nuestra política de privacidad, o si desea presentar una queja, póngase en contacto con nosotros al correo indicado arriba.</p>
          <p>Las solicitudes de acceso de sujeto a direcciones IP requerirán la prueba del solicitante de que son los propietarios de la dirección IP. El cumplimiento de tales solicitudes puede no ser posible ya que la ofuscación de IP hace que los datos no identificables y, por tanto, no disponibles.</p>
          <h3 className="uk-text-bold">
          Cambios Corporativos
          </h3>
          <p>En caso de que haya un cambio en la titularidad de TATANCA, o una fusión, disolución, reorganización u otro evento de este tipo, nos reservamos el derecho de transferir todos los datos a la parte adquirente u otras entidades dentro de nuestro grupo.  Todas las empresas del grupo estarán obligadas a las mismas garantías de responsabilidad y cuidado en el manejo de datos personales, de las que se indican en este documento.</p>
          <h3 className="uk-text-bold">
          Publicación de los ganadores
          </h3>
          <p>Todo ganador estará sujeto a aceptar y facilitar la difusión publica de la acreditación de entrega del premio a través de newsletters, las redes sociales de la plataforma, a través de la página web <a href='https://llenaygana.com/'></a> o en cualquier otro medio de comunicación o información, por un plazo no mayor de un (1) año, utilizando su imagen y nombre.</p>
          <h3 className="uk-text-bold">
          Disposiciones Generales
          </h3>
          <p>Tatanca podrá variar las fechas de los sorteos, entrega de premios, así como cancelar, interrumpir o posponer la misma por causas atendibles o debido a la ocurrencia de fenómenos naturales, acontecimientos sociales, políticos o cualesquiera otras circunstancias que impidan el desenvolvimiento normal de la misma.</p>
          <h3 className="uk-text-bold">
          Exclusiones
          </h3>
          <p>No tendrán derecho a la obtención de premios personas menores de 16 años, empleados de Tatanca y de otras empresas del grupo Pagés y o empleados de marcas patrocinadoras de los módulos de encuestas.</p>
          <h3 className="uk-text-bold">
          Actualizaciones de la política de privacidad        
          </h3>
          <p>Podemos actualizar nuestra política de privacidad de vez en cuando, y nos reservamos el derecho de hacerlo sin previo aviso, por lo tanto, debe revisar esta política cada vez que visite nuestro sitio.</p>
          <p className="uk-text-bold">Fecha política de privacidad</p>
          <p>Agosto 1, 2022</p>
          </div>
        </div>
  </div> );
}
 
export default Terms;
