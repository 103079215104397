import React, { Suspense } from 'react';
import './App.reducers';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import HomeScreen from './Layout/Home/HomeScreen';
import './css/style.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import history from './browserHistory';
import Loader from 'react-loader-spinner'
const Dashboard = React.lazy(() => import('./containers/Dashboard/index'));
const DetallesCuenta = React.lazy(() => import('./containers/Dashboard/DetallesCuenta'));
const EditProfile = React.lazy(() => import('./containers/Dashboard/EditProfile'));
const EditProfileUn = React.lazy(() => import('./containers/Dashboard/EditProfileUn'));
const QuestionsProfile = React.lazy(() => import('./containers/Questions/QuestionsProfile'));
const Contact = React.lazy(() => import('./containers/contact/index'))
const Questions = React.lazy(() => import('./containers/Questions/Questions'));
const firebasaeui = React.lazy(() => import('./components/firebaseui'));
const Signup = React.lazy(() => import('./containers/Signup/Signup'));
const EndSurvey = React.lazy(() => import('./components/EndSurvey'));
const Faqs = React.lazy(() => import('./components/Faqs'));
const Winners = React.lazy(() => import('./components/Winners'));
UIkit.use(Icons);
require('dotenv').config();

const detectHost = () => {
  let currentHost = window.location.hostname;
  currentHost = currentHost.split(".")
  let port = (window.location.port != "") ? ":" + window.location.port : window.location.port
  if (currentHost[0].toLocaleLowerCase() === "admin") {
    currentHost[0] = "api"
  } else {
    if (currentHost[0].toLocaleLowerCase() === "localhost") {

    } else {
      currentHost = currentHost.reverse();
      currentHost.push("api");
      currentHost = currentHost.reverse()
    }
  }
  currentHost = currentHost.join('.')
  return "https://" + currentHost + port;
} 

export const REACT_APP_API_URL = detectHost()
// export const REACT_APP_API_URL = 'http://localhost:3002';



const CheckAuth = () => {

  let url = history.location.pathname.includes('/admin');

  let results = {
    pathname: '/',
    pass: false,
  }

  if (url) {
    let tokenAdmin = localStorage.getItem('tokenAdmin');
    if (tokenAdmin != null && tokenAdmin != undefined) {
      results.pass = true
    } else {
      results.pathname = '/admin/login'
    }

  } else {
    let tokenClient = localStorage.getItem('token');
    if (tokenClient != null && tokenClient != undefined) {
      results.pass = true
    } else {
      results.pathname = '/'
    }
  }

  return results;

};

const AuthRoute = ({ component: Component, ...rest }) => {
  let checkauth = CheckAuth();
  return (
    <Route
      {...rest}
      render={(props) => (checkauth.pass ? <Component {...props} /> : <Redirect to={{ pathname: checkauth.pathname }} />)}
    />
  )
};

const App = () => {
  return (

    <Router>
      <Switch>
        <Route path="/" exact render={(props) => <HomeScreen {...props} />} />
        <Suspense fallback={<Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs

        />}>
          <Route path="/signup" exact render={(props) => <Signup {...props} />} />
          <AuthRoute path="/dashboard" component={Dashboard} />
          <AuthRoute exact path="/account" component={DetallesCuenta} />
          <Route path="/question/profile" component={QuestionsProfile} />
          <Route path="/firebaseui" component={firebasaeui} />
          <AuthRoute path="/question/survey" component={Questions} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/Winners" component={Winners} />
          <AuthRoute path="/account/update" component={EditProfile} />
          <Route path="/account/UpdateUserName" component={EditProfileUn} />
          <AuthRoute path="/llenaygana/end" component={EndSurvey} />
          <Route exact path="/contacts" component={Contact}></Route>
        </Suspense>
      </Switch>
    </Router>
  )
};

export default App;
