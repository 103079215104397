import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import UIkit from 'uikit';
import pdf from "../../assets/PROMOCION_LLENA_Y_GANA_Bases_legales_2023.pdf";
import Terms from "../../components/Terms";

const FooterHome = () => (
  <Fragment>
    <footer className="footer">
      <div className="icons">
        <a href="https://web.facebook.com/Llena-y-Gana-103379451433868" target="_blank" data-uk-icon="icon: facebook; ratio: 2"></a>
        <a href="https://www.instagram.com/llenaygana/" target="_blank" data-uk-icon="icon: instagram; ratio: 2"></a>
      </div>

      <small className="uk-padding">
        ©2023 Llena y Gana. Todos los derechos reservados. 2023.04.30
      </small>

      <nav className="uk-navbar-transparent" data-uk-navbar>
        <div className="uk-navbar-center">
          <ul className="uk-navbar-nav">
            <li>
              <a className="a-text-nav" href={pdf} target="_blank">
                Bases Legales
              </a>
            </li>
            <li>
              <a className="a-text-nav" href="/contacts">
                Contactos
              </a>
            </li>
            <li>
              <Link className="a-text-nav" to="/faqs">
                Preguntas Frecuentes
              </Link>
            </li>
            <li />
            <li>
              <a
                className="a-text-nav"
                href="#"
                uk-toggle="target: #modal-container"
              >
                Términos y condiciones
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <Terms />
     
    </footer>
  </Fragment>
);

export default FooterHome;
