import React, { useEffect, useGlobal, useState } from "reactn";
import logoOpinaYGana from "../../assets/images/logo.png";
import personaje from "../../assets/images/personaje.png";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import history from "../../browserHistory";
import swal from "sweetalert2";

const HeaderHome = (props) => {
  const [accountExist] = useGlobal("accountExist");
  const [title, setTitle] = useState("Accede con tu correo");
  const [info, setInfo] = useState(
    ""
  );

  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");

  useEffect(() => {
    if (accountExist) {
      setTitle("Código de seguridad");
      setInfo(
        "Hola, te hemos enviado un código de verificación a tu correo, el mismo expira en un tiempo de 30 minutos."
      );
    } else {
      setTitle("Accede con tu correo");
      setInfo("");
    }
  }, [accountExist]);

  let closeSession = (e) => {
    e.preventDefault();

    swal
      .fire({
        title: "¿Estás seguro que quieres salir?",
        icon: "warning",
        confirmButtonText: "Sí, deseo salir.",
        confirmButtonColor: "#008f39",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
        cancelButtonColor: "#ff264c",
        showCloseButton: true,
      })
      .then((resp) => {
        if (resp.value) {
          localStorage.removeItem("token");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          history.push("/");
          history.go();
        }
      });
  };

  return (
    <div className="uk-container">
      <header
        className="home-header uk-margin-large uk-inline landing "
        uk-scrollspy="cls: uk-animation-fade"
      >
        <Modal
          title={title}
          info={info}
          field={{
            tag: "input",
            class: "uk-input",
            id: "emailSignUp",
            type: "email",
            name: "email",
            placeholder: "usuario@email.com",
          }}
        ></Modal>

        <nav
          id="main-nav"
          className="uk-navbar-container uk-navbar-transparent"
          data-uk-navbar
        >
          <div
            style={firstName ? { display: "none" } : { display: "block" }}
            className="uk-navbar-right"
          >
            <ul className="uk-navbar-nav">
              <li>
                <a href="/" data-uk-toggle="target: #modal-sections">
                  <span className="uk-text-small">INICIAR SESIÓN</span>
                </a>
              </li>
              <li className="uk-parent">
                <a href="#" data-uk-toggle="target: #modal-sections">
                  <i className="fas fa-user"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            style={
              firstName == null ? { display: "none" } : { display: "block" }
            }
            className="uk-navbar-right"
          >
            <ul className="uk-navbar-nav uk-flex uk-flex-middle">
              <li>
                <a>
                  <span
                    uk-icon="icon: menu; ratio: 2"
                    data-uk-toggle="target: #offcanvas-reveal"
                  />
                </a>
              </li>
            </ul>
            <div
              id="offcanvas-reveal"
              data-uk-offcanvas="mode: slide; flip: true; overlay: true"
            >
              <div className="uk-offcanvas-bar">
                <a href="#" className="uk-offcanvas-close" data-uk-close></a>

                <ul className="uk-nav uk-nav-default mobile-menu uk-padding-small">
                  <li>
                    <a href="#">
                      <b> ¡Hola, {firstName}!</b>
                    </a>
                  </li>

                  <li className="uk-margin uk-nav-header">
                    <a href="/account">
                      <span
                        className="uk-margin-small-right"
                        uk-icon="icon: user"
                      ></span>
                      Mi perfil
                    </a>
                  </li>
                  <li className="uk-nav-divider"></li>
                  <li className="uk-margin uk-nav-header">
                    <a href="/dashboard">
                      <span
                        className="uk-margin-small-right"
                        uk-icon="icon: comments"
                      ></span>
                      Mis Encuestas
                    </a>
                  </li>
                  <li className="uk-nav-divider"></li>

                  <li className="uk-margin uk-nav-header">
                    <a
                      href="#"
                      className="uk-text-small"
                      onClick={(e) => {
                        closeSession(e);
                      }}
                    >
                      <span
                        className="uk-margin-small-right"
                        uk-icon="icon: sign-out"
                      ></span>
                      Cerrar Sesión
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <a href="/">
          <img className="logo" src={logoOpinaYGana} width="230" alt="" />
        </a>

        <div className="header_content">
          <div className="uk-container">
            <div className="uk-grid-row-collapse uk-flex-middle" data-uk-grid>
              <div className="uk-width-1-2@m intro-text">
                <h1 className="uk-margin-remove-bottom uk-text-uppercase">
                  <span>Donde</span> tu opinión <br />
                  <span> te hace</span> ganar premios
                </h1>
                <p>
                Sé parte de la gran comunidad de investigación de mercados, 
                con darnos tu opinión podrás ganar dinero formando parte de las encuestas.
                </p>
                <Button
                  type="primary"
                  text="¡Comienza a Ganar!"
                  size="medium"
                  icon={false}
                  gridSize="skew-btn-1-home uk-width-1@m uk-width-3-4@l uk-width-1@s"
                  url="/signup"
                />
              </div>

              <div className="uk-width-1-2@m uk-inline uk-padding uk-flex-first@m">
                <img
                  // className="uk-position-center-right girl-sofa"
                  src={personaje}
                  width="600"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderHome;
