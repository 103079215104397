import React, { Fragment, Suspense } from "react";
import HeaderHome from "./HeaderHome";
import FooterHome from "./FooterHome";
import Loader from "react-loader-spinner";

const StepsHome = React.lazy(() => import("./StepsHome"));
const CallActionHome = React.lazy(() => import("./CallActionHome"));

const HomeScreen = () => (
  <Fragment>
    <main className="wrapper-bg">
      <HeaderHome />
      <Suspense
        fallback={
          <div className="uk-position-center">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={4000} //3 secs
            />
          </div>
        }
      >
        <StepsHome />
        <CallActionHome />
        {/* <section className="uk-padding-large"></section> */}
      </Suspense>
      <FooterHome />
    </main>
  </Fragment>
);

export default HomeScreen;
