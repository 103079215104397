import React from "react";
import { Link } from "react-router-dom";

const Button = ({ type, size, gridSize, text, icon, url, shadow }) => (
  <Link
    rel="preload"
    to={url ? url : ""}
    className={`uk-button uk-button-default uk-button-${size} ${gridSize} btn-${type}`}
  >
    {text}

    {icon ? <span data-uk-icon="icon: arrow-right; ratio: 2"></span> : ""}
  </Link>
);

export default Button;
